var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "35%",
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.open,
        title: _vm.formTitle,
      },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "a-form-model",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "上级菜单", prop: "parentId" } },
            [
              _c("a-tree-select", {
                staticStyle: { width: "100%" },
                attrs: {
                  "dropdown-style": { maxHeight: "400px", overflow: "auto" },
                  "tree-data": _vm.menuOptions,
                  placeholder: "请选择",
                  replaceFields: {
                    children: "children",
                    title: "menuName",
                    key: "menuId",
                    value: "menuId",
                  },
                  "tree-default-expand-all": "",
                },
                model: {
                  value: _vm.form.parentId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "parentId", $$v)
                  },
                  expression: "form.parentId",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "菜单类型", prop: "menuType" } },
            [
              _c(
                "a-radio-group",
                {
                  attrs: { "button-style": "solid" },
                  model: {
                    value: _vm.form.menuType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "menuType", $$v)
                    },
                    expression: "form.menuType",
                  },
                },
                [
                  _c("a-radio-button", { attrs: { value: "M" } }, [
                    _vm._v("目录"),
                  ]),
                  _c("a-radio-button", { attrs: { value: "C" } }, [
                    _vm._v("菜单"),
                  ]),
                  _c("a-radio-button", { attrs: { value: "F" } }, [
                    _vm._v("按钮"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.form.menuType != "F"
            ? _c(
                "a-form-model-item",
                { attrs: { label: "图标", prop: "icon" } },
                [
                  _c(
                    "a-space",
                    { attrs: { size: "large" } },
                    [
                      _vm.allIcon[_vm.form.icon + "Icon"]
                        ? _c("a-icon", {
                            attrs: {
                              component: _vm.allIcon[_vm.form.icon + "Icon"],
                            },
                          })
                        : _vm._e(),
                      !_vm.allIcon[_vm.form.icon + "Icon"]
                        ? _c("a-icon", { attrs: { type: _vm.form.icon } })
                        : _vm._e(),
                      _c(
                        "a-button",
                        {
                          attrs: { type: "dashed" },
                          on: { click: _vm.selectIcon },
                        },
                        [_vm._v(" 选择图标 ")]
                      ),
                      _vm.iconVisible
                        ? _c(
                            "a",
                            {
                              staticStyle: { "margin-left": "8px" },
                              on: { click: _vm.cancelSelectIcon },
                            },
                            [
                              _vm._v(" 收起 "),
                              _c("a-icon", { attrs: { type: "up" } }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.iconVisible
            ? _c(
                "a-card",
                {
                  attrs: {
                    "body-style": { padding: "10px 20px" },
                    bordered: false,
                  },
                },
                [
                  _c("icon-selector", {
                    attrs: { svgIcons: _vm.iconList, allIcon: _vm.allIcon },
                    on: { change: _vm.handleIconChange },
                    model: {
                      value: _vm.form.icon,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "icon", $$v)
                      },
                      expression: "form.icon",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-form-model-item",
            { attrs: { label: "菜单名称", prop: "menuName" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.form.menuName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "menuName", $$v)
                  },
                  expression: "form.menuName",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "排序", prop: "orderNum" } },
            [
              _c("a-input-number", {
                staticStyle: { width: "100%" },
                attrs: { min: 0 },
                model: {
                  value: _vm.form.orderNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "orderNum", $$v)
                  },
                  expression: "form.orderNum",
                },
              }),
            ],
            1
          ),
          _vm.form.menuType != "F"
            ? _c(
                "a-form-model-item",
                { attrs: { label: "是否外链", prop: "isFrame" } },
                [
                  _c(
                    "a-radio-group",
                    {
                      attrs: { "button-style": "solid" },
                      model: {
                        value: _vm.form.isFrame,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "isFrame", $$v)
                        },
                        expression: "form.isFrame",
                      },
                    },
                    [
                      _c("a-radio-button", { attrs: { value: "0" } }, [
                        _vm._v("是"),
                      ]),
                      _c("a-radio-button", { attrs: { value: "1" } }, [
                        _vm._v("否"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.form.menuType != "F"
            ? _c(
                "a-form-model-item",
                { attrs: { label: "路由地址", prop: "path" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.form.path,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "path", $$v)
                      },
                      expression: "form.path",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.form.menuType == "C"
            ? _c(
                "a-form-model-item",
                { attrs: { label: "组件路径", prop: "component" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.form.component,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "component", $$v)
                      },
                      expression: "form.component",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.form.menuType != "M"
            ? _c(
                "a-form-model-item",
                { attrs: { label: "权限标识", prop: "perms" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.form.perms,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "perms", $$v)
                      },
                      expression: "form.perms",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.form.menuType != "F"
            ? _c(
                "a-form-model-item",
                { attrs: { label: "是否显示", prop: "visible" } },
                [
                  _c(
                    "a-radio-group",
                    {
                      attrs: { "button-style": "solid" },
                      model: {
                        value: _vm.form.visible,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "visible", $$v)
                        },
                        expression: "form.visible",
                      },
                    },
                    _vm._l(_vm.visibleOptions, function (d, index) {
                      return _c(
                        "a-radio-button",
                        { key: index, attrs: { value: d.dictValue } },
                        [_vm._v(_vm._s(d.dictLabel))]
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.form.menuType != "F"
            ? _c(
                "a-form-model-item",
                { attrs: { label: "状态", prop: "status" } },
                [
                  _c(
                    "a-radio-group",
                    {
                      attrs: { "button-style": "solid" },
                      model: {
                        value: _vm.form.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status",
                      },
                    },
                    _vm._l(_vm.statusOptions, function (d, index) {
                      return _c(
                        "a-radio-button",
                        { key: index, attrs: { value: d.dictValue } },
                        [_vm._v(_vm._s(d.dictLabel))]
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.form.menuType == "C"
            ? _c(
                "a-form-model-item",
                { attrs: { label: "是否缓存", prop: "isCache" } },
                [
                  _c(
                    "a-radio-group",
                    {
                      attrs: { "button-style": "solid" },
                      model: {
                        value: _vm.form.isCache,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "isCache", $$v)
                        },
                        expression: "form.isCache",
                      },
                    },
                    [
                      _c("a-radio-button", { attrs: { value: "1" } }, [
                        _vm._v("缓存"),
                      ]),
                      _c("a-radio-button", { attrs: { value: "0" } }, [
                        _vm._v("不缓存"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "bottom-control" },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v(" 保存 ")]
                  ),
                  _c(
                    "a-button",
                    { attrs: { type: "dashed" }, on: { click: _vm.cancel } },
                    [_vm._v(" 取消 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }